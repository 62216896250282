#mobile-header-links,
#mobile-footer-links {
	background: @header-slogan-bg;
	border-bottom: 1px solid @gray-bg;
	font-size: 12px;
	height: 35px;
	//line-height: 0;
	margin: 0 -@body-out-padding @body-out-padding;
	overflow: hidden;
	padding: 0;
	text-align: center;
	white-space: nowrap;
	
	& > * {
		font-size: 12px;
		height: 34px;
		line-height: 34px;
		margin: 0;
		padding-bottom: 0;
		padding-top: 0;
	}
	
	.mobile-slogan-history,
	.mobile-slogan-sexshop,
	.main-cat-switcher-mobile,
	.mobile-slogan-app,
	.mobile-slogan-games {
		.icon-f{
			float: left;
			font-size: 16px;
			line-height: 34px;
			margin-right: 5px;
		}
	}
	.mobile-slogan-sexshop {
		.icon-f{
			font-size: 18px;
		}
	}

	.mobile-slogan-games {
		.icon-f{
			margin-top: -1px;
		}
	}

	.mobile-jm {
		.flag-small {
			margin-bottom: -1px;
		}
	}
	
	.mobile-slogan-red{
		font-size: 0;
		
		span {
			font-size: 12px;
			line-height: 34px;
			vertical-align: top;
		}
		.icon-f{
			color: @theme-color;
			font-size: 30px;
			margin-left: 3px;
		}
	}
	
	.main-cat-switcher-mobile {
		cursor: default;
		opacity: 0.5;
		padding-right: 0;
		transition: opacity .2s;
		
		&.init-ok {
			cursor: pointer;
			opacity: 1;
		}
		
		.icf-caret-down {
			float: none;
		}
		
		.icon-flag-gay {
			float: left;
			margin-right: 4px;
			margin-top: 11px;
		}
		
		.icf-sexe-woman-v2,
		.icf-sexe-trans-v2 {
			font-size: 19px;
		}

		.icf-caret-down {
			color: @gray;
			font-size: 14px;
			margin-right: 0;
			vertical-align: middle;
		}
	}
	
	.live-cams {
		.icon-f {
			font-size: 20px;
			line-height: 32px;
			padding: 0 5px;
		}
	}
	
	.alt {
		font-size: 14px;
	}
}

#mobile-header-links,
#mobile-footer-links {
	align-items: center;
	display: flex;
	justify-content: space-evenly;
	
	& > * {
		padding: 0 4px;
	}
	
	&.mobile-landscape-show {
		display: none;
	}
	
	@media @media-ratio-horizontal {
		&.mobile-landscape-show {
			display: block;
			display: flex;
		}
		&.mobile-portrait-show {
			display: none;
		}
	}
}

#mobile-footer-links {
	border-bottom: 0;
	border-top: 1px solid @gray-lighter;
	margin: 0;
	.alt {
		font-size: 14px;
	}
}

#home-search {
	overflow: hidden;
	.icf-home,
	.live-cams {
		color: @search-header-submit-bg;
		float: left;
		font-size: 30px;
		line-height: 40px;
		text-align: center;
		width: 30px;
	}
	.live-cams {
		background: @search-header-submit-bg;
		border: 0;
		border-radius: 4px;
		color: lighten(@search-header-inp-color, 15%);
		height: 38px;
		margin: 1px 0 0;
		padding: 0;
		.icon-f {
			font-size: 24px;
			line-height: 38px;
		}
	}
	form {
		float: left;
		width: calc(~"100% - 30px");
	}
}

#home-search {
	.search-input,
	.search-submit {
		border-color: @search-header-submit-bg;
	}
}

.search-autocomplete {
	z-index: @zindex-autocomplete;
	position: fixed;
	li {
		padding: 2px 10px;
		font-size: 14px;
	}
}

.listing-settings-popup {
	
	&.x-popup.below.right {
		.x-popup-arrow {
			right: 11px;
		}
	}
	
	&.in-header {
		.x-popup-content {
			.max-height-100vh(40px);
			overflow: auto;
			
			.video-page & {
				max-height: none;
			}
			
			@media @media-ratio-horizontal {
				max-height: none;
			}
		}
	}
}

#mobile-messages-btn {
	float: left;
	margin-right: 3px;
	line-height: 1px;
}

#mobile-login-menu {
	position: fixed;
	right: 3px;
	max-width: 80%;
}

.language-switcher-popup {
	font-size: 16px;
	position: fixed;
	top: 40px;
	
	.x-popup-content {
		//max-height: calc(~"100vh - 40px " - @body-out-padding);
		.max-height-100vh(40px + @body-out-padding);
		overflow: auto;
		
		a {
			padding-bottom: 7px;
			padding-top: 7px;
			font-weight: normal;
		}
	}
}
